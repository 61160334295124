import { mapGetters, mapActions, mapMutations } from "vuex"
import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import ThemisSaveFilter from "@/components/save-filter"
import ThemisDecision from "@/components/shared/decision"
import ThemisInput from "@/components/shared/input"
import { TABLE_NAMES } from "@/constants"
import { getListOfStrings } from "@/utils"
import { compareArray, compareDates } from "@/utils"

export default {
  name      : "Filters",
  components: {
    ThemisSaveFilter,
    ThemisDecision,
    ThemisInput
  },
  data() {
    return {
      tableSearchFilter                : undefined,
      search                           : undefined,
      helpCenterUrl                    : process.env.VUE_APP_ALL_FILTERS_HELP_URL,
      isFilterNameDuplicate            : false,
      showEditFilterDialog             : false,
      showFilterMenu                   : false,
      currentFilter                    : {},
      showDeleteFilterDialog           : false,
      showShareFilterDialog            : false,
      showShareFilterConfirmationDialog: false,
      filterAccess                     : null,
      filterGroups                     : []
    }
  },
  computed: {
    ...mapGetters({
      users                    : "users/users",
      filters                  : "filters/filters",
      groups                   : "groups/groups",
      isUpdatingName           : "filters/isUpdatingName",
      isNameUpdated            : "filters/isNameUpdated",
      isUpdatingAccess         : "filters/isUpdatingAccess",
      isAccessUpdated          : "filters/isAccessUpdated",
      filterNameUpdateError    : "filters/filterNameUpdateError",
      savedFilterUpdatePolicies: "accessControl/savedFilterUpdatePolicies",
      isRemovingFilter         : "filters/isRemovingFilter",
      isFilterRemoved          : "filters/isFilterRemoved",
      savedFilterRemovePolicies: "accessControl/savedFilterRemovePolicies"
    }),
    usersMap() {
      const usersMap = new Object()
      for (const user of this.users) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    headersForTable() {
      return getHeadersForTable(TABLE_NAMES.FILTERS, this.$t.bind(this))
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.FILTERS, this.$t.bind(this))
    },
    itemsForTable() {
      return [...this.filters]
        .sort((first, second) => {
          return compareDates(first.createdAt, second.createdAt)
        })
        .map(filter => {
          return {
            id            : filter.id,
            name          : filter.name,
            creatorId     : this.usersMap[filter.creatorId]?.name,
            showFilterMenu: false,
            sharedWith    : filter.access === "groups" ?
              filter.groups.map(group => group.name).join(", ")
              : filter.access === "everyone" ? this.$t("2159") : this.$t("2160"),
            access: filter.access,
            groups: filter.groups
          }
        })
    },
    itemsForSearch() {
      return getListOfStrings(this.itemsForTable, ["id"])
    },
    currentSavedFiltersUpdatePolicies() {
      return this.savedFilterUpdatePolicies.filter(savedFilterUpdatePolicy =>
        this.filters.find(filter =>
          filter.id === savedFilterUpdatePolicy.id
        )
      )
    },
    currentSavedFiltersRemovePolicies() {
      return this.savedFilterRemovePolicies.filter(savedFilterRemovePolicy =>
        this.filters.find(filter =>
          filter.id === savedFilterRemovePolicy.id
        )
      )
    },
    filterAccessesToSelect() {
      return [{
        value: "everyone",
        text : this.$t("2180")
      }, {
        value: "private",
        text : this.$t("2181")
      }, {
        value: "groups",
        text : this.$t("2182")
      }]
    },
    groupsToSelect() {
      return this.groups.map(group => {
        return {
          id  : group.id,
          text: group.name
        }
      })
    },
    disableShareSubmitButton() {
      return this.isUpdatingAccess ||
      (this.filterAccess === "groups" && !this.filterGroups.length) ||
      (this.filterAccess === this.currentFilter.access && this.filterAccess !== "groups") ||
      (this.filterAccess === "groups" && compareArray(this.filterGroups, this.currentFilter.groups.map(group => group.id)))
    }
  },
  methods: {
    ...mapActions({
      updateFilter: "filters/updateFilter",
      notify      : "shared/notify",
      removeFilter: "filters/removeFilter"
    }),
    ...mapMutations({
      resetFilterUpdateError: "filters/resetFilterUpdateError"
    }),
    customFilterForTable(value) {
      return this.tableSearchFilter.findIndex(element => value && value.toString().includes(element)) + 1
    },
    handleUpdateFilter(data) {
      this.updateFilter(data)
    },
    canUpdateFilterName(filterId) {
      const savedFilterUpdatePolicy = this.currentSavedFiltersUpdatePolicies.find(currentSavedFilterUpdatePolicy =>
        currentSavedFilterUpdatePolicy.id === filterId
      )
      return savedFilterUpdatePolicy?.set?.name !== undefined
    },
    canRemoveFilter(filterId) {
      const savedFilterRemovePolicy = this.currentSavedFiltersRemovePolicies.find(currentSavedFilterRemovePolicy =>
        currentSavedFilterRemovePolicy.id === filterId
      )
      return savedFilterRemovePolicy?.set?.remove !== undefined
    },
    canShareFilter(filterId) {
      const savedFilterUpdatePolicy = this.currentSavedFiltersUpdatePolicies.find(currentSavedFilterUpdatePolicy =>
        currentSavedFilterUpdatePolicy.id === filterId
      )
      return savedFilterUpdatePolicy?.set?.access !== undefined
    },
    menuItems(filter) {
      return  [{
        condition: this.canShareFilter(filter.id),
        title    : this.$t("2186"),
        icon     : "mdi-account-multiple-outline",
        class    : "info-lighten-4",
        action   : () => this.handleShareFilterAction(filter)
      }, {
        condition: this.canUpdateFilterName(filter.id),
        title    : this.$t("2168"),
        icon     : "mdi-pencil-outline",
        class    : "info-lighten-4",
        action   : () => this.handleRenameFilter(filter)
      }, {
        condition: this.canRemoveFilter(filter.id),
        title    : this.$t("2215"),
        icon     : "mdi-trash-can-outline",
        class    : "info-lighten-4",
        action   : () => this.handleDeleteFilter(filter)
      }].filter(item => item.condition)
    },
    handleRenameFilter(filter) {
      this.currentFilter        = filter
      this.showEditFilterDialog = true
    },
    handleClickOnFiltersMenu(filter) {
      filter.showFilterMenu = !filter.showFilterMenu
    },
    handleDeleteFilter(filter) {
      this.currentFilter          = filter
      this.showDeleteFilterDialog = true
    },
    closeRemoveFilterDecision() {
      this.currentFilter          = null
      this.showDeleteFilterDialog = false
    },
    handleRemoveFilter() {
      this.removeFilter({ id: this.currentFilter.id })
    },
    handleShareFilterAction(filter) {
      this.currentFilter         = filter
      this.filterAccess          = filter.access
      this.filterGroups          = filter.groups.map(group => group.id)
      this.showShareFilterDialog = true
    },
    handleSubmitShareFilter() {
      if (this.filterAccess === "private" && this.currentFilter.access !== "private") {
        this.showShareFilterConfirmationDialog = true
        this.showShareFilterDialog             = false
      } else {
        this.updateFilter({
          id    : this.currentFilter.id,
          access: this.filterAccess,
          groups: this.filterGroups
        })
      }
    },
    closeShareFilterDecisionDialog() {
      this.showShareFilterConfirmationDialog = false
      this.currentFilter                     = null
    },
    handleContinueDecisionDialogShareFilter() {
      this.updateFilter({
        id    : this.currentFilter.id,
        access: this.filterAccess,
        groups: this.filterGroups
      })
    },
    handleClickOnRow(item){
      this.$router.push({ name: "filter", params: { filterId: item.id } })
    }
  },
  watch: {
    tableSearchFilter: function(newSearchFilters) {
      this.search = newSearchFilters ? newSearchFilters.toString() : undefined
    },
    filterNameUpdateError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isFilterNameDuplicate = true
        } else {
          this.isFilterNameDuplicate = false
        }
      }
    },
    isNameUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "2170"
          })
          this.showEditFilterDialog = false
        }
      }
    },
    isRemovingFilter: {
      handler: function(newValue) {
        this.$DECISIONS.REMOVE_FILTER.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_FILTER.pActions[1].buttonProps.loading  = newValue
      }
    },
    isFilterRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.closeRemoveFilterDecision()
          this.notify({
            type: "success",
            text: "2216"
          })
        }
      }
    },
    filterAccess: {
      handler: function(newValue) {
        if ((newValue === "private" || newValue === "everyone")
          && this.filterGroups.length) {
          this.filterGroups = []
        }
      }
    },
    isUpdatingAccess: {
      handler: function(newValue) {
        this.$DECISIONS.UNSHARE_FILTER.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.UNSHARE_FILTER.pActions[1].buttonProps.loading  = newValue
      }
    },
    isAccessUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "2205"
          })
          this.showShareFilterDialog             = false
          this.showShareFilterConfirmationDialog = false
        }
      }
    }
  }
}